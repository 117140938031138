import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import MainContainer from "./components/MainContainer/MainContainer";
import Navbar from "./components/Navbar/Navbar";
import LanguageButton from "./components/LanguageButton/LanguageButton";
import DarkModeButton from "./components/DarkModeButton/DarkModeButton";
import Maintenance from "./pages/Maintenance";
import { t } from "i18next";

function App() {
  useEffect(() => {
    document.title = t("site.title");
  }, []);
  const isMaintenance = true;
  console.log(process.env.REACT_APP_MAINTENANCE);
  return (
    process.env.REACT_APP_MAINTENANCE !== "true" ? (<>
      <div className="h-screen">
        <div className="hidden lg:w-10/12 lg:flex lg:justify-between lg:mx-auto h-14 items-center">
          <div className="flex gap-x-4">
            <LanguageButton />
            <DarkModeButton />
          </div>
          <Navbar />
        </div>
        <div className="h-[calc(100vh-4rem)]  flex flex-col gap-y-3 lg:flex-row lg:justify-center lg:items-center lg:gap-x-4 p-3">
          <Sidebar />
          <MainContainer />
        </div>
        <div className="lg:hidden">
          <Navbar />
        </div>
      </div>
    </>
    ) : (
      <Maintenance />
    )
  )
}


export default App;
