// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './translations/en/translations.json';
import enResume from './translations/en/resume.json';
import itResume from './translations/it/resume.json';
import itTranslations from './translations/it/translations.json';

i18n.use(initReactI18next).init({
    lng: 'en', // Lingua predefinita
    fallbackLng: 'en', // Lingua di fallback
    debug: false,
    ignoreJSONStructure: false,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: enTranslations,
            resume: enResume,
        },
        it: {
            translation: itTranslations,
            resume: itResume,
        },
        // Aggiungi altre lingue e traduzioni qui
    },
}, undefined);
export default i18n;
